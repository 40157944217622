import { render, staticRenderFns } from "./SVGIcon.vue?vue&type=template&id=1c35bd8d&scoped=true&"
import script from "./SVGIcon.vue?vue&type=script&lang=js&"
export * from "./SVGIcon.vue?vue&type=script&lang=js&"
import style0 from "./SVGIcon.vue?vue&type=style&index=0&id=1c35bd8d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c35bd8d",
  null
  
)

export default component.exports