<template>
  <svg class="icon" :class="`icon-${icon}`">
    <use :xlink:href="`${svgSpriteSrc}#${icon}`" />
  </svg>
</template>

<script>
  export default {
    name: 'SVGIcon',
    data() {
      return {
        svgSpriteSrc: ''
      }
    },
    mounted() {
      this.svgSpriteSrc = document.getElementById('svg-sprite').getAttribute('src')
    },
    props: {
      icon: {
        type: String,
        default: ''
      }
    }
  }
</script>

<style lang="scss" scoped>
.icon {
  color: #ffffff;
}

.icon-sign-in {
  width: 44px;
  height: 36px;
}
.icon-chevron-down,
.icon-heartbeat,
.icon-bike,
.icon-rowing,
.icon-swimming {
  width: 22px;
  height: 18px;
}
.icon-youtube {
  width: 35px;
  height: 24px;
}
.icon-map-route {
  width: 35px;
  height: 24px;
}
.icon-fullscreen-on,
.icon-fullscreen-off {
  width: 100%;
  height: 100%;
  max-width: 35px;
  max-height: 35px;
}
</style>
